import * as Sentry from '@sentry/react'
import Cookies from 'js-cookie'
import { useNavigate } from 'react-router-dom'
import { CheckoutReducerAction, CheckoutState } from 'contexts/checkout/type'
import { fetchLatestCheckoutState } from 'contexts/checkout'
import { penceToPounds } from 'utils/penceToPounds'
import { BillingRequest, BillingRequestFlow } from '@gocardless/react-dropin'
import { AnalyticsEventEcommerceObj, newAnalyticsEvent, oldAnalyticsEvent } from 'utils/analytics'
import { removeLocalStorage } from 'utils/localStorage'
import { registerCustomer } from 'api/registerCustomer'
import { calculateNextASVDate } from 'api/calculateNextASVDate'
import { PaymentIntent } from '@chargebee/chargebee-js-types'
import { getCookieDomain } from 'utils/getCookieDomain'
import { updateLeadUrl } from 'api/updateLeadUrl'

export const masterObject = {
    registerCustomerKey: '',
}

export const generateUniqueKey = (checkoutState: CheckoutState) => {
    const { street, postcode } = checkoutState
    return `${street}_${postcode}`.replace(/\s/g, '').toLowerCase()
}

export const hashMarketingData = async (
    email: string | undefined,
    contractCreatedDate: string | undefined,
    address: string | undefined
) => {
    const marketingBuffer = new TextEncoder().encode(`${email}|${address}|${contractCreatedDate}`)
    const hash = await crypto.subtle.digest('SHA-256', marketingBuffer)
    const hashArray = Array.from(new Uint8Array(hash))
    const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('')

    return hashHex
}

export interface ThirdPartyValues {
    goCardless?: {
        billing_request_id: string
        billing_request_flow_id: string
        creditor_id: string
        customer_id: string
        customer_bank_account_id: string
        customer_billing_detail_id: string
        mandate_request_id: string
        mandate_request_mandate_id: string
    }
    chargebee?: {
        payment_intent_id: string
    }
}

export const handleThirdPartySuccess = async (
    thirdPartyValues: ThirdPartyValues,
    dispatch: React.Dispatch<CheckoutReducerAction>,
    navigate: ReturnType<typeof useNavigate>
) => {
    const latestState = await fetchLatestCheckoutState()
    const registerCustomerRequestKey = generateUniqueKey(latestState)

    if (
        // Context: GoCardless Drop-in can be called multiple times, so we need to prevent
        // duplicate requests from being sent to the API. This is a temporary fix until we
        // implement a better solution.
        (typeof latestState.registerCustomerRequest !== 'undefined' &&
            latestState.registerCustomerRequest.key === registerCustomerRequestKey) ||
        registerCustomerRequestKey === masterObject.registerCustomerKey
    ) {
        // eslint-disable-next-line no-console
        console.error('Duplicate request detected but was prevented by key')
        navigate('/register-customer')
        return
    }

    dispatch({
        type: 'setCheckout',
        data: {
            registerCustomerRequest: {
                isRequesting: true,
                key: registerCustomerRequestKey,
            },
        },
    })

    masterObject.registerCustomerKey = registerCustomerRequestKey

    // Temporarily show loading page
    navigate('/register-customer')

    try {
        const [registerCustomerResponse, nextServiceMonth] = await Promise.all([
            registerCustomer({
                ...latestState,
                ...thirdPartyValues,
            }),
            calculateNextASVDate(latestState?.date, latestState?.packageId),
        ])

        dispatch({
            type: 'setCheckout',
            data: {
                nextServiceDate: nextServiceMonth,
            },
        })
        const cookieLeadToken = Cookies.get('lead-token')
        if (cookieLeadToken && latestState.querystring) {
            await updateLeadUrl(cookieLeadToken)
            Cookies.remove('lead-token')
        }
        Sentry.setContext('validation', registerCustomerResponse)

        if (
            registerCustomerResponse.contractId !== null &&
            !Number.isNaN(Number(registerCustomerResponse.contractId))
        ) {
            dispatch({
                type: 'setCheckout',
                data: {
                    accountId: registerCustomerResponse.accountId,
                    contractCreatedDate: registerCustomerResponse.contractDate,
                    contractId: registerCustomerResponse.contractId,
                    propertyId: registerCustomerResponse.propertyId,
                    marketingOptIn: registerCustomerResponse.marketingOptIn,
                    registerCustomerRequest: {
                        isRequesting: false,
                        isError: false,
                        key: registerCustomerRequestKey,
                    },
                    referralLink: registerCustomerResponse.referralLink,
                },
            })
            if (!registerCustomerResponse.marketingOptIn) {
                const expiresInSixyMinutes = 0.0417 // 1/24 - unit is in days
                const hashed = await hashMarketingData(
                    latestState.email,
                    registerCustomerResponse.contractDate,
                    latestState.street
                )
                const marketingData = {
                    type: hashed,
                }
                Cookies.set('marketing_preferences', JSON.stringify(marketingData), {
                    domain: getCookieDomain(),
                    sameSite: 'strict',
                    expires: expiresInSixyMinutes,
                })
            }

            Cookies.set(
                'post_register_data',
                JSON.stringify({
                    contract_id: registerCustomerResponse.contractId,
                    property_id: registerCustomerResponse.propertyId,
                }),
                {
                    domain: getCookieDomain(),
                    sameSite: 'strict',
                    expires: 2, // 2 days
                }
            )

            const monthlyPrice = penceToPounds(
                Math.round(latestState.monthlyTotalPrice - latestState.monthlyDiscount)
            )

            const annualPrice = penceToPounds(
                Math.round((latestState.monthlyTotalPrice - latestState.monthlyDiscount) * 12)
            )

            const ecommerceObj: AnalyticsEventEcommerceObj = {
                event: 'purchase',
                ecommerce: {
                    transaction_id: (registerCustomerResponse.contractId || '').toString(),
                    value: latestState.billingType === 'monthly' ? monthlyPrice : annualPrice,
                    currency: 'GBP',
                    items: [
                        {
                            item_id: `${latestState.packageId}`,
                            item_name: latestState.packageType,
                            currency: 'GBP',
                            item_category: latestState.productName || '',
                            item_category2: latestState.billingType,
                            item_category3: `${latestState.contribution}`,
                            price:
                                latestState.billingType === 'monthly' ? monthlyPrice : annualPrice,
                            quantity: 1,
                        },
                    ],
                },
            }

            newAnalyticsEvent(ecommerceObj)
            window.dataLayer?.push({ 'user.email': latestState.email })
            oldAnalyticsEvent(
                'Cover confirmation',
                'Confirmed',
                (registerCustomerResponse.contractId || '').toString()
            )

            navigate('/confirmation')
        } else {
            dispatch({
                type: 'setCheckout',
                data: {
                    registerCustomerRequest: {
                        isRequesting: false,
                        isError: true,
                        key: registerCustomerRequestKey,
                        errorCode: registerCustomerResponse.errorCode || undefined,
                    },
                },
            })

            Sentry.captureMessage(
                registerCustomerResponse.errorCode
                    ? 'Internal Server Error - Registering Customer'
                    : 'Validation Error - Registering Customer'
            )
        }
    } finally {
        masterObject.registerCustomerKey = ''
        removeLocalStorage(
            'pandoraCustomerId',
            'goCardlessCustomerCreationID',
            'pandoraContractId',
            'customerEmail'
        )
    }
}

export const handleGCSuccess =
    (dispatch: React.Dispatch<CheckoutReducerAction>, navigate: ReturnType<typeof useNavigate>) =>
    async (billingRequest: BillingRequest, billingRequestFlow: BillingRequestFlow) => {
        await handleThirdPartySuccess(
            {
                goCardless: {
                    billing_request_id: billingRequest.id || '',
                    billing_request_flow_id: billingRequestFlow.id || '',
                    creditor_id: billingRequest.links?.creditor || '',
                    customer_id: billingRequest.links?.customer || '',
                    customer_bank_account_id: billingRequest.links?.customer_bank_account || '',
                    customer_billing_detail_id: billingRequest.links?.customer_billing_detail || '',
                    mandate_request_id: billingRequest.links?.mandate_request || '',
                    mandate_request_mandate_id: billingRequest.links?.mandate_request_mandate || '',
                },
            },
            dispatch,
            navigate
        )
    }

export const handleChargebeeSuccess = async (
    paymentIntent: PaymentIntent,
    dispatch: React.Dispatch<CheckoutReducerAction>,
    navigate: ReturnType<typeof useNavigate>
) => {
    await handleThirdPartySuccess(
        {
            chargebee: {
                payment_intent_id: paymentIntent.id,
            },
        },
        dispatch,
        navigate
    )
}

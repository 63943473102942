import { Promo } from '../promo.class'

export const ctmPromo = new Promo()
ctmPromo.addPhase({
    period: {
        start: new Date(2025, 0, 23, 9, 0, 0),
        end: new Date(2025, 1, 28, 23, 59, 59),
    },
    countdown: true,
    pricebooks: {
        promoBook: 63,
        fullPriceBook: 51,
    },
    banner: {
        primaryText: 'WINTER SALE - up to 20% off select plans',
        primaryTextMobile: <>Save up to 20% on select plans</>,
        secondaryText: (
            <>
                For new customers only; see{' '}
                <a
                    href="https://www.hometree.co.uk/wp-content/uploads/2025/01/CtM-Hometree-JanuaryStaggered-Discount-Promotion-TCs.docx-1.pdf"
                    target="_blank"
                    rel="noreferrer"
                >
                    T&Cs
                </a>{' '}
                for details
            </>
        ),
    },
    ctm: {
        exclusive: true,
        stickers: {
            enabled: true,
            text: null,
            yourBoiler: (
                <>
                    5%
                    <br />
                    off
                </>
            ),
            yourHeating: (
                <>
                    12%
                    <br />
                    off
                </>
            ),
            yourHome: (
                <>
                    20%
                    <br />
                    off
                </>
            ),
        },
        landing: {
            header: <></>,
            subheader: <></>,
        },
        plans: {
            header: 'Up to 20% Off Select Plans Until 28th February',
            headerMobile: '20% Off Select Plans Until 28th Feb 2025',
            subheader: (
                <>
                    Offer ends at midnight 28th February - for new customers only; see{' '}
                    <a
                        href="https://www.hometree.co.uk/wp-content/uploads/2025/01/CtM-Hometree-JanuaryStaggered-Discount-Promotion-TCs.docx-1.pdf"
                        target="_blank"
                        rel="noreferrer"
                    >
                        T&Cs
                    </a>{' '}
                    for details
                </>
            ),
            strikethrough: true,
        },
    },
    checkout: {
        strikethrough: true,
    },
})

import { ReactComponent as Instagram } from 'assets/icons/instagram.svg'
import { ReactComponent as LinkedIn } from 'assets/icons/linkedin.svg'
import { ReactComponent as Twitter } from 'assets/icons/twitter.svg'
import { ReactComponent as Facebook } from 'assets/icons/facebook.svg'
import { ReactComponent as FooterLogo } from 'assets/logos/hometree.svg'
import { ReactComponent as LogoIcon } from 'assets/logos/mable.svg'
import { ReactComponent as FooterCaret } from 'assets/footerCaret.svg'
import { useState } from 'react'
import styles from './index.module.scss'
import footerLinks from './config/footerLinks.json'

const { coverPlans, helpAndAdvice, workWithUs, company, legalLinks, whyHometree } = footerLinks

function Footer(): JSX.Element {
    const socialMedia = {
        'https://www.instagram.com/hometreeuk/': <Instagram />,
        'https://www.linkedin.com/company/hometree-uk/': <LinkedIn />,
        'https://twitter.com/hometreeuk/': <Twitter />,
        'https://www.facebook.com/hometreeUK/': <Facebook />,
    }

    const [expanded, setExpanded] = useState<null | string>(null)

    const mapToLinks = (mapping: Record<string, string>) => {
        return (
            <ul>
                {Object.entries(mapping).map(([name, link]) => {
                    return (
                        <li key={`footer-links-${name}`}>
                            <a target="_blank" href={link} rel="noreferrer">
                                {name}
                            </a>
                        </li>
                    )
                })}
            </ul>
        )
    }

    const onClickExpandMenu = (expandedKey: string) => () => {
        setExpanded(expanded === expandedKey ? null : expandedKey)
    }

    return (
        <footer className={styles.footer}>
            <div>
                <FooterLogo className={styles.footerLogo} />
                <div className={styles.linksContainer}>
                    <div>
                        <div
                            className={`${styles.linkGroup} ${
                                expanded === 'plans' ? styles.expanded : ''
                            }`}
                        >
                            <button type="button" onClick={onClickExpandMenu('plans')}>
                                <h3>Plans &amp; Services</h3>
                                <FooterCaret />
                            </button>
                            {mapToLinks(coverPlans)}
                        </div>
                        <br />
                        <div
                            className={`${styles.linkGroup} ${
                                expanded === 'why' ? styles.expanded : ''
                            }`}
                        >
                            <button type="button" onClick={onClickExpandMenu('why')}>
                                <h3>Why Hometree</h3>
                                <FooterCaret />
                            </button>
                            {mapToLinks(whyHometree)}
                        </div>
                        <div className={`${styles.socialMediaLinks}  ${styles.hideMobile}`}>
                            {Object.entries(socialMedia).map(([link, component]) => {
                                return (
                                    <a
                                        key={link}
                                        href={link}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {component}
                                    </a>
                                )
                            })}
                        </div>
                    </div>

                    <div>
                        <div
                            className={`${styles.linkGroup} ${
                                expanded === 'help' ? styles.expanded : ''
                            }`}
                        >
                            <button type="button" onClick={onClickExpandMenu('help')}>
                                <h3>Help Centre</h3>
                                <FooterCaret />
                            </button>
                            {mapToLinks(helpAndAdvice)}
                        </div>
                        <br />
                        <div
                            className={`${styles.linkGroup} ${
                                expanded === 'help' ? styles.expanded : ''
                            }`}
                        >
                            <button type="button" onClick={onClickExpandMenu('help')}>
                                <h3>Work with us</h3>
                                <FooterCaret />
                            </button>
                            {mapToLinks(workWithUs)}
                        </div>
                    </div>

                    <div>
                        <div
                            className={`${styles.linkGroup} ${
                                expanded === 'company' ? styles.expanded : ''
                            }`}
                        >
                            <button type="button" onClick={onClickExpandMenu('company')}>
                                <h3>Company</h3>
                                <FooterCaret />
                            </button>
                            {mapToLinks(company)}
                        </div>
                    </div>

                    <div>
                        <div
                            className={`${styles.linkGroup} ${
                                expanded === 'legal' ? styles.expanded : ''
                            }`}
                        >
                            <button type="button" onClick={onClickExpandMenu('legal')}>
                                <h3>Legal</h3>
                                <FooterCaret />
                            </button>
                            {mapToLinks(legalLinks)}
                        </div>
                    </div>
                </div>

                <div className={`${styles.socialMediaLinks} ${styles.hideDesktop}`}>
                    {Object.entries(socialMedia).map(([link, component]) => {
                        return (
                            <a key={link} href={link} target="_blank" rel="noopener noreferrer">
                                {component}
                            </a>
                        )
                    })}
                </div>

                <div className={styles.hr}>
                    <LogoIcon />
                </div>

                <p className={styles.legalText}>
                    Hometree is a trading name of Hometree Marketplace Limited and Hometree Services
                    Limited which are registered at Hamilton House, 4 Mabledon Place, King’s Cross,
                    London, WC1H 9BB. Hometree Marketplace Limited has Company Number: 09808066 and
                    VAT Number: 228718977 and is a GasSafe registered company. Hometree Marketplace
                    Ltd (FRN 743711) is authorised and regulated by the Financial Conduct Authority
                    for the provision of credit broking for its boiler installations. Hometree
                    Services Limited has Company Number: 12535561 and VAT Number: 364324895.
                    Hometree Services Limited (FRN 935444) is an Appointed Representative of Xact
                    Risk Solutions Ltd (FRN 590914) who are authorised and regulated by the
                    Financial Conduct Authority
                </p>
            </div>
        </footer>
    )
}
export default Footer

import { Promo } from '../promo.class'

export const corePromo = new Promo()

corePromo.addPhase({
    period: {
        start: new Date(2025, 0, 16, 10, 0, 0),
        end: new Date(2025, 0, 22, 23, 59, 59),
    },

    countdown: true,
    pricebooks: {
        promoBook: 61,
        fullPriceBook: 51,
    },
    banner: {
        primaryText: <>Winter Offer - Up to 25% Off&nbsp;</>,
        primaryTextMobile: <>Winter Offer - Up to 25% Off&nbsp;</>,
        secondaryText: (
            <>
                <span>
                    <a
                        href="https://www.hometree.co.uk/wp-content/uploads/2025/01/Winter-2025-Sale-Up-to-25-Off-Promotion-Offer-Terms.pdf"
                        target="_blank"
                        rel="noreferrer"
                    >
                        T&Cs
                    </a>{' '}
                    apply
                </span>
            </>
        ),
    },
    checkout: {
        strikethrough: true,
    },
})

corePromo.addPhase({
    period: {
        start: new Date(2025, 0, 23, 10, 0, 0),
        end: new Date(2025, 1, 6, 23, 59, 59),
    },

    countdown: true,
    pricebooks: {
        promoBook: 63,
        fullPriceBook: 51,
    },
    banner: {
        primaryText: <>Limited Time Offer - Up to 20% Off&nbsp;</>,
        primaryTextMobile: <>Limited Time Offer - Up to 20% Off&nbsp;</>,
        secondaryText: (
            <>
                <span>
                    <a
                        href="https://www.hometree.co.uk/wp-content/uploads/2025/01/Jan_Feb-2025-Sale-Up-to-20-Off-Promotion-Offer-Terms.pdf"
                        target="_blank"
                        rel="noreferrer"
                    >
                        T&Cs
                    </a>{' '}
                    apply
                </span>
            </>
        ),
    },
    checkout: {
        strikethrough: true,
    },
})

import React, { useEffect, useState, useRef } from 'react'
import { useCheckoutContext } from 'contexts/checkout'
import { generateGCFlow } from 'api/goCardless'
import Collapsible from 'components/collapsible'
import { storeLeadDataInCookie } from 'utils/storeLeadDataInCookie'

import { ReactComponent as TickLogo } from 'assets/tick.svg'
import Cookies from 'js-cookie'
import AddressForm from './addressForm/index'

import StartDateAndPayment from './startDateAndPayment'
import ContactFormSection from './contactFormSection'
import styles from './index.module.scss'

function CheckoutForm(): JSX.Element {
    const { state, dispatch } = useCheckoutContext()
    const [activeSection, setActiveSection] = useState('contact')
    const [addressSectionValid, setAddressSectionValid] = useState<boolean>(false)
    const [contactSectionValid, setContactSectionValid] = useState<boolean>(false)
    const [dateSectionValid, setDateSectionValid] = useState<boolean>(false)
    const [gcDropInOptions, setGcDropInOptions] = useState<{
        flowID: string
        environment: string
    } | null>(null)
    const startDateAndPaymentRef = useRef<HTMLDivElement>(null)
    const propertyInfoRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (activeSection === 'payment') {
            generateGCFlow(state).then((response) => {
                if (response) {
                    setGcDropInOptions({
                        flowID: response.flowID,
                        environment: response.environment,
                    })
                }
            })
        }

        storeLeadDataInCookie(dispatch, state)

        const postRegisterCookie = Cookies.get('post_register_data')
        if (postRegisterCookie) {
            Cookies.remove('post_register_data')
        }

        // TODO catch any errors, send to sentry and show feedback to user
    }, [activeSection])

    const onCollapsibleHeaderClick = (sectionName: string) => () => {
        if (sectionName === 'contact') {
            setActiveSection(sectionName)
        }

        if (contactSectionValid && sectionName === 'address') {
            setActiveSection(sectionName)
        }

        if (contactSectionValid && addressSectionValid && sectionName === 'date') {
            setActiveSection(sectionName)
        }
    }

    return (
        <div className={styles.checkoutContainer}>
            <h1>Checkout</h1>
            <p>
                Please enter your details below - don&apos;t worry, we will only use your data to
                set up the cover plan you have chosen. Please see our{' '}
                <a
                    href="https://www.hometree.co.uk/privacy-policy.html"
                    target="_blank"
                    rel="noreferrer"
                >
                    privacy policy
                </a>{' '}
                for full details.
            </p>

            <Collapsible
                header={
                    <div className={styles.header}>
                        <h2>Contact information</h2>
                        {contactSectionValid && <TickLogo />}
                    </div>
                }
                collapsed={activeSection !== 'contact'}
                onHeaderClick={onCollapsibleHeaderClick('contact')}
            >
                <ContactFormSection
                    setContactSectionValid={setContactSectionValid}
                    contactSectionValid={contactSectionValid}
                    onSubmit={() => {
                        setActiveSection('address')

                        setTimeout(() => {
                            if (propertyInfoRef.current) {
                                window.scrollTo({
                                    top: propertyInfoRef.current.offsetTop - 120,
                                    behavior: 'smooth',
                                })
                            }
                        }, 50)
                    }}
                />
            </Collapsible>

            <Collapsible
                header={
                    <div className={styles.header} ref={propertyInfoRef}>
                        <h2>Property information</h2>
                        {addressSectionValid && <TickLogo />}
                    </div>
                }
                collapsed={activeSection !== 'address'}
                onHeaderClick={onCollapsibleHeaderClick('address')}
                disabled={!contactSectionValid}
            >
                <AddressForm
                    setAddressSectionValid={setAddressSectionValid}
                    addressSectionValid={addressSectionValid}
                    onSubmit={() => {
                        setActiveSection('payment')

                        setTimeout(() => {
                            if (startDateAndPaymentRef.current) {
                                window.scrollTo({
                                    top: startDateAndPaymentRef.current.offsetTop - 100,
                                    behavior: 'smooth',
                                })
                            }
                        }, 50)
                    }}
                />
            </Collapsible>

            <Collapsible
                header={
                    <div className={styles.header} ref={startDateAndPaymentRef}>
                        <h2>Start date &amp; payment</h2>
                        {dateSectionValid && <TickLogo />}
                    </div>
                }
                collapsed={activeSection !== 'payment'}
                onHeaderClick={onCollapsibleHeaderClick('payment')}
                disabled={!addressSectionValid}
            >
                <StartDateAndPayment
                    setDateSectionValid={setDateSectionValid}
                    gcDropInOptions={gcDropInOptions}
                />
            </Collapsible>
        </div>
    )
}

export default CheckoutForm
